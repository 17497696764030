export default [

  
  /*
  *Μπουζουκια
  */

{
  τίτλος: "Μπουζούκι εξάχορδο",
  title: "Six-course bouzouki",
  id: "#Β6Ε1",
  type: "Μπουζούκι",
  διαστάσειςΟργάνου: "Κλίμακα 68cm",
  dimensionsScale: "Scale 68cm",
  διαστάσειςΗχείου: {
    μήκος: "37.5cm",
    πλάτος : "28cm",
    βάθος: "17cm"
  },
  ξύλοΚαπακιού: "Έλατο ΑΑΑ, παλαιό",
  woodSoundboard: "Spruce AAA, old",
  ξύλοΣκάφους: "Ελληνική καρυδιά σκούρα",
  woodBack: "Greek wallnut tree (dark)",
  μάνικο: "Φλαμούρι με κόντρες βέγγε",
  neck: "Silver linden with wenge",
  αριθμόςΔουγών : "40",
  ταστιέρα: "Έβενος",
  fretboard: "Ebony",
  κλειδιά: "Grover",
  φιγούρα: "Ξύλινη: σφένδαμος,καρυδιά",
  figure: "Wooden: maple,wallnut tree",
  λούστρο: "Ματ",
  vernice: "Matt",
  περιγραφή: "Επαγγελματικό εξάχορδο μπουζούκι. Ήχος μεσαίος. Στην τιμή δεν συμπεριλαμβάνεται θήκη.",
  description: "Professional six-course bouzouki. Medium pitch sound. Case is not included in the price.",
  τιμή: 1000,
  pictures: ["bouzouki/koutsourh 1.jpg","bouzouki/koutsourh 2.jpg"]
},

{
  τίτλος: "Μπουζούκι οχτάχορδο",
  title: "Eight-course bouzouki",
  id: "#Β8Ε2",
  type: "Μπουζούκι",
  διαστάσειςΟργάνου: "Κλίμακα 67cm",
  dimensionsScale: "Scale 67cm",
  διαστάσειςΗχείου: {
    μήκος: "38cm",
    πλάτος : "30cm",
    βάθος: "-"
  },
  ξύλοΚαπακιού: "Έλατο ΑΑΑ, παλαιό",
  woodSoundboard: "Spruce AAA, old",
  ξύλοΣκάφους: "Ελληνική καρυδιά μαύρη",
  woodBack: "Greek wallnut tree (black)",
  μάνικο: "Φλαμούρι με κόντρες βέγγε",
  neck: "Silver linden with wenge",
  αριθμόςΔουγών : "60",
  ταστιέρα: "Έβενος",
  fretboard: "Ebony",
  κλειδιά: "Grover",
  φιγούρα: "Πλαστική με όστρακα",
  figure: "Plastic with seashell",
  λούστρο: "Γυαλιστερό",
  vernice: "Shiny",
  περιγραφή: "Επαγγελματικό οχτάχορδο μπουζούκι. Ήχος μπασο-μεσαίος. Στην τιμή δεν συμπεριλαμβάνεται θήκη.",
  description: "Professional eight-course bouzouki. Medium-bass sound. Case is not included in the price.",
  τιμή: 1600,
  pictures: ["bouzouki/kapet 1 (1).jpg","bouzouki/kapet 1 (2).jpg","bouzouki/kapet 1 (3).jpg","bouzouki/kapet 1 (4).jpg","bouzouki/kapet 1 (5).jpg"]
},

{
  τίτλος: "Μπουζούκι οχτάχορδο",
  title: "Eight-course bouzouki",
  id: "#Β8Ε1",
  type: "Μπουζούκι",
  διαστάσειςΟργάνου: "Κλίμακα 67cm",
  dimensionsScale: "Scale 67cm",
  διαστάσειςΗχείου: {
    μήκος: "37.5cm",
    πλάτος : "30cm",
    βάθος: "17cm"
  },
  ξύλοΚαπακιού: "Έλατο ΑΑΑ, παλαιό",
  woodSoundboard: "Spruce AAA, old",
  ξύλοΣκάφους: "Ελληνική καρυδιά μαύρη",
  woodBack: "Greek wallnut tree (black)",
  μάνικο: "Φλαμούρι με κόντρες βέγγε",
  neck: "Silver linden with wenge",
  αριθμόςΔουγών : "40",
  ταστιέρα: "Έβενος",
  fretboard: "Ebony",
  κλειδιά: "Grover",
  φιγούρα: "Πλαστική με οστράκινα λουλούδια",
  figure: "Plastic with flowers of seashell",
  λούστρο: "Γυαλιστερό",
  vernice: "Shiny",
  περιγραφή: "Επαγγελματικό οχτάχορδο μπουζούκι. Ήχος μεσαίος. Στην τιμή δεν συμπεριλαμβάνεται θήκη.",
  description: "Professional eight-course bouzouki. Medium pitch sound. Case is not included in the price.",
  τιμή: 1400,
  pictures: ["bouzouki/8-chord a1.jpg","bouzouki/8-chord a2.jpg","bouzouki/8-chord a3.jpg"]
},

{
  τίτλος: "Μπουζούκι εξάχορδο",
  title: "Six-course bouzouki",
  id: "#Β6Ε3",
  type: "Μπουζούκι",
  διαστάσειςΟργάνου: "Κλίμακα 68cm",
  dimensionsScale: "Scale 68cm",
  διαστάσειςΗχείου: {
    μήκος: "-",
    πλάτος : "-",
    βάθος: "-"
  },
  ξύλοΚαπακιού: "Έλατο ΑΑΑ",
  woodSoundboard: "Spruce AAA",
  ξύλοΣκάφους: "Καρυδιά φυσική",
  woodBack: "Wallnut tree natural",
  μάνικο: "Φλαμούρι με κόντρες βέγγε",
  neck: "Silver linden with wenge",
  αριθμόςΔουγών : "40",
  ταστιέρα: "Έβενος",
  fretboard: "Ebony",
  κλειδιά: "Grover",
  φιγούρα: "Καρυδιά, σφένδαμος",
  figure: "Wallnut tree, malpe",
  λούστρο: "Σαντινέ",
  vernice: "Satine",
  περιγραφή: "Επαγγελματικό εξάχορδο μπουζούκι. Ήχος μεσαίος. Στην τιμή δεν συμπεριλαμβάνεται θήκη.",
  description: "Professional eight-course bouzouki. Medium sound. Case is not included in the price.",
  τιμή: 850,
  pictures: ["bouzouki/bouzouki 6-chord.jpeg"]
},

{
  τίτλος: "Μπουζούκι οκτάχορδο",
  title: "Eight-course bouzouki",
  id: "#Β8Ε4",
  type: "Μπουζούκι",
  διαστάσειςΟργάνου: "Κλίμακα 67",
  dimensionsScale: "Scale 67",
  διαστάσειςΗχείου: {
    μήκος: "-",
    πλάτος : "-",
    βάθος: "-"
  },
  ξύλοΚαπακιού: "Έλατο ΑΑΑ",
  woodSoundboard: "Spruce AAA",
  ξύλοΣκάφους: "Καρυδιά",
  woodBack: "Wallnut tree",
  μάνικο: "fl",
  neck: "-",
  αριθμόςΔουγών : "40",
  ταστιέρα: "Έβενος",
  fretboard: "Ebony",
  κλειδιά: "Grover",
  φιγούρα: "Καρυδιά, σφένδαμος",
  figure: "Wallnut tree, malpe",
  λούστρο: "Σαντινέ",
  vernice: "Satine",
  περιγραφή: "Επαγγελματικό οκτάχορδο μπουζούκι με ρόμβους όστρακα στους οδηγούς. Ήχος μεσαίος. Στην τιμή δεν συμπεριλαμβάνεται θήκη.",
  description: "Professional eight-course bouzouki. Medium sound. Case is not included in the price.",
  τιμή: 850,
  pictures: ["bouzouki/bouzouki 8-chord old.jpeg"]
},

{
  τίτλος: "Μπουζούκι οκτάχορδο",
  title: "Eight-course bouzouki",
  id: "#Β8Ε5",
  type: "Μπουζούκι",
  διαστάσειςΟργάνου: "Κλίμακα 67",
  dimensionsScale: "Scale 67",
  διαστάσειςΗχείου: {
    μήκος: "-",
    πλάτος : "-",
    βάθος: "-"
  },
  ξύλοΚαπακιού: "Έλατο ΑΑΑ",
  woodSoundboard: "Spruce AAA",
  ξύλοΣκάφους: "Καρυδιά βαμμένη",
  woodBack: "Wallnut tree",
  μάνικο: "Φλαμούρι με κόντρες βέγγε",
  neck: "Silver linden with wenge",
  αριθμόςΔουγών : "60",
  ταστιέρα: "Έβενος",
  fretboard: "Ebony",
  κλειδιά: "Grover",
  φιγούρα: "Όστρακο (ο αετός)",
  figure: "Seashell (the eagle)",
  λούστρο: "Γυαλιστερό",
  vernice: "Shiny",
  περιγραφή: "Επαγγελματικό οκτάχορδο μπουζούκι με φιγούρα αετό από όστρακα. Στην τιμή δεν συμπεριλαμβάνεται θήκη.",
  description: "Professional eight-course bouzouki with the figure of an eagle (seashellsnpm). Case is not included in the price.",
  τιμή: 1400,
  pictures: ["bouzouki/bouzouki aetos.jpeg"]
},

/*
*Κιθάρες
*/
{
  τίτλος: "Τζαζ κιθάρα",
  title: "Jazz guitar",
  id: "#K6Ε1",
  type: "Κιθάρα",
  διαστάσειςΟργάνου: "Κλίμακα 65cm",
  dimensionsScale: "Scale 65cm",
  διαστάσειςΗχείου: {
    μήκος: "-",
    πλάτος : "-",
    βάθος: "-"
  },
  ξύλοΚαπακιού: "Έλατο ΑΑΑ, παλαιό",
  woodSoundboard: "Spruce AAA, old",
  ξύλοΣκάφους: "Μπουμπίγκα",
  woodBack: "Rosewood",
  μάνικο: "Σφενδάμι",
  neck: "Maple",
  αριθμόςΔουγών : "0",
  ταστιέρα: "Έβενος",
  fretboard: "Ebony",
  κλειδιά: "Schaller",
  φιγούρα: "-",
  figure: "-",
  λούστρο: "Γυαλιστερό ακρυλικό",
  vernice: "Shiny acrylic",
  περιγραφή: "Επαγγελματική ηλεκτρο-ακουστική κιθάρα με όστρακα στην ταστιέρα. Στην τιμή δεν συμπεριλαμβάνεται θήκη.",
  description: "Professional electric-acoustic guitar with seashell on the fretboard. Case is not included in the price.",
  τιμή: 2500,
  pictures: ["guitar/hliadh 1.jpg","guitar/hliadh 2.jpg","guitar/hliadh 3.jpg","guitar/hliadh 4.jpg"],
  avail:false,
},

{
  τίτλος: "Κιθάρα κλασσική",
  title: "Classical guitar",
  id: "#K6Ο2",
  type: "Κιθάρα",
  διαστάσειςΟργάνου: "Κλίμακα 64cm",
  dimensionsScale:"Scale 64cm" ,
  διαστάσειςΗχείου: {
    μήκος: "48cm",
    πλάτος : "35.5cm max",
    βάθος: "9.3-9.8cm"
  },
  ξύλοΚαπακιού: "Έλατο ΑΑΑ",
  woodSoundboard: "Spruce AAA",
  ξύλοΣκάφους: "Μαόνι",
  woodBack: "Mahogany",
  μάνικο: "Μαόνι με κόντρα βέγγε",
  neck: "Mahogany with wenge",
  αριθμόςΔουγών : "0",
  ταστιέρα: "Έβενος",
  fretboard: "Ebony",
  κλειδιά: "Grover",
  φιγούρα: "0",
  figure: "0",
  λούστρο: "Φυσικό βερνίκι",
  vernice: "Natural vernice",
  περιγραφή: "Studio κιθάρα μοντέλο Jose Romanillos. Στην τιμή δεν συμπεριλαμβάνεται θήκη.",
  description: "Studio classical guitar, Jose Romanillos model. Case is not included in the price.",
  τιμή: 1000,
  pictures: ["guitar/classic maoni 4.jpg","guitar/classic maoni 1.jpg","guitar/classic maoni 2.jpg","guitar/classic maoni 3.jpg"],
  avail: false,
},

{
  τίτλος: "Κιθάρα κλασσική",
  title: "Classical guitar",
  id: "#K6S7",
  type: "Κιθάρα",
  διαστάσειςΟργάνου: "Κλίμακα 64cm",
  dimensionsScale:"Scale 64cm" ,
  διαστάσειςΗχείου: {
    μήκος: "48cm",
    πλάτος : "35.5cm max",
    βάθος: "9.3-9.8cm"
  },
  ξύλοΚαπακιού: "Έλατο ΑΑΑ",
  woodSoundboard: "Spruce AAA",
  ξύλοΣκάφους: "Ινδικός παλίσανδρος",
  woodBack: "Indian rosewood",
  μάνικο: "Μαόνι με κόντρα βέγγε",
  neck: "Mahogany with wenge",
  αριθμόςΔουγών : "0",
  ταστιέρα: "Έβενος",
  fretboard: "Ebony",
  κλειδιά: "Grover",
  φιγούρα: "0",
  figure: "0",
  λούστρο: "Φυσικό βερνίκι",
  vernice: "Natural vernice",
  περιγραφή: "Επαγγελματική κιθάρα μοντέλο Jose Romanillos. Στην τιμή δεν συμπεριλαμβάνεται θήκη.",
  description: "Professional classical guitar, Jose Romanillos model. Case is not included in the price.",
  τιμή: 1700,
  pictures: ["guitar/dikia mas 1.jpg","guitar/dikia mas 2.jpg"],
  avail: false,
},

{
  τίτλος: "Κιθάρα κλασσική",
  title: "Classical guitar",
  id: "#K6Ε3",
  type: "Κιθάρα",
  διαστάσειςΟργάνου: "Κλίμακα 64cm",
  dimensionsScale: "Scale 64cm",
  διαστάσειςΗχείου: {
    μήκος: "48.5cm",
    πλάτος : "36.5cm",
    βάθος: "10.5-11cm"
  },
  ξύλοΚαπακιού: "Έλατο ΑΑ, παλαιό",
  woodSoundboard: "Spruce AA, old",
  ξύλοΣκάφους: "Ινδικός παλίσανδρος",
  woodBack: "Indian rosewood",
  μάνικο: "Μαόνι με κόντρα βέγγε",
  neck: "Mahogany with wenge",
  αριθμόςΔουγών : "0",
  ταστιέρα: "Έβενος",
  κλειδιά: "Grover",
  φιγούρα: "Ξύλινη",
  λούστρο: "Φυσικό βερνίκι",
  vernice: "Natural vernice",
  περιγραφή: "Επαγγελματική κλασσική κιθάρα. Ήχος μπασο-μεσαίος. Στην τιμή δεν συμπεριλαμβάνεται θήκη.",
  description: "Professional 7-string classical guitar, cut-away. Medium-bass sound. Case is not included in the price.",
  τιμή: 1600,
  pictures: ["guitar/classic 2.jpg","guitar/classic 1.jpg","guitar/classic 3.jpg"],
},

{
  τίτλος: "Ρεμπετοκιθάρα",
  title: "Rebetiko guitar",
  id: "#K6Ε4",
  type: "Κιθάρα",
  διαστάσειςΟργάνου: "Κλίμακα 65cm",
  dimensionsScale: "Scale 65cm",
  διαστάσειςΗχείου: {
    μήκος: "48.5cm",
    πλάτος : "39cm max",
    βάθος: "10.5cm"
  },
  ξύλοΚαπακιού: "Έλατο ΑΑΑ",
  woodSoundboard: "Spruce AAA",
  ξύλοΣκάφους: "Σφένδαμος",
  woodBack: "Maple",
  μάνικο: "Μαόνι με κόντρα βέγγε",
  neck: "Mahogany with wenge",
  αριθμόςΔουγών : "0",
  ταστιέρα: "Έβενος",
  fretboard: "Ebony",
  κλειδιά: "Grover",
  φιγούρα: "-",
  figure: "-",
  λούστρο: "Φυσικό βερνίκι",
  vernice: "Natural vernice",
  περιγραφή: "Επαγγελματική ρεμπετοκιθάρα με όστρακα στη ροζέτα και στους οδηγούς. Ήχος μπασο-μεσαίος. Στην τιμή δεν συμπεριλαμβάνεται θήκη.",
  description: "Professional rebetiko guitar with seashell on the fretboard. Medium-bass sound. Case is not included in the price.",
  τιμή: 1200,
  pictures: ["guitar/rempeto 1.jpg","guitar/rempeto 2.jpg"],
  avail:false,
},

{
  τίτλος: "Κιθάρα 12-χορδη κλασσική πατέντα",
  title: "12-course classical guitar patent",
  id: "#K12Ε6",
  type: "Κιθάρα",
  διαστάσειςΟργάνου: "Κλίμακα 60",
  dimensionsScale: "Scale 60",
  διαστάσειςΗχείου: {
    μήκος: "-",
    πλάτος : "-",
    βάθος: "-"
  },
  ξύλοΚαπακιού: "Έλατο ΑΑ",
  woodSoundboard: "Spruce AA",
  ξύλοΣκάφους: "Ινδικός παλίσανδρος",
  woodBack: "Indian rosewood",
  μάνικο: "Μαόνι με κόντρα βέγγε",
  neck: "Mahogany with wenge",
  αριθμόςΔουγών : "0",
  ταστιέρα: "Rosewood",
  fretboard: "Rosewood",
  κλειδιά: "Ebony",
  φιγούρα: "-",
  figure: "-",
  λούστρο: "Ματ",
  vernice: "Matt",
  περιγραφή: "Επαγγελματική κλασσική 12-χορδη κιθάρα (πατέντα). Στην τιμή δεν συμπεριλαμβάνεται θήκη.",
  description: "Professional classical 12-chord guitar (pantent). Case is not included in the price.",
  τιμή: 1200,
  pictures: ["guitar/guitar patenta.jpg"],
  avail:false,
},

/*
*Λαούτα
*/

{
  τίτλος: "Λαούτο στεριανό",
  title: "Laouto stergiano",
  id: "#Λ6Ε1",
  type: "Λαούτο",
  διαστάσειςΟργάνου: "Κλίμακα 69.5cm",
  dimensionsScale: "Scale 69.5cm",
  διαστάσειςΗχείου: {
    μήκος: "-",
    πλάτος : "-",
    βάθος: "-"
  },
  ξύλοΚαπακιού: "Έλατο ΑΑΑ, παλαιό",
  woodSoundboard: "Spruce AAA, old",
  ξύλοΣκάφους: "Ελληνική καρυδιά σκούρα",
  woodBack: "Greek wallnut tree (dark)",
  μάνικο: "Φλαμούρι με κόντρες βέγγε",
  neck: "Silver linden with wenge",
  αριθμόςΔουγών : "0",
  ταστιέρα: "Παλίσσανδρος",
  fretboard: "Rosewood",
  κλειδιά: "Grover",
  φιγούρα: "Ξύλινη καρυδιά",
  figure: "Wooden (wallnut tree)",
  λούστρο: "Ματ",
  vernice: "Matt",
  περιγραφή: "Επαγγελματικό στεριανό λαούτο. Στην τιμή δεν συμπεριλαμβάνεται θήκη.",
  description: "Professional stergiano laouto. Case is not included in the price.",
  τιμή: 1000,
  pictures: ["laouto/laouto front.jpg","laouto/laouto back.jpg","laouto/laouto karaoulo.jpg","laouto/laouto keys.jpg"]
},

{
  τίτλος: "Λαούτο στεριανό",
  title: "Laouto stergiano",
  id: "#Λ6Ε3",
  type: "Λαούτο",
  διαστάσειςΟργάνου: "Κλίμακα 69.3cm",
  dimensionsScale: "Scale 69.3cm",
  διαστάσειςΗχείου: {
    μήκος: "47",
    πλάτος : "34",
    βάθος: "-"
  },
  ξύλοΚαπακιού: "Έλατο ΑΑΑ",
  woodSoundboard: "Spruce AAA",
  ξύλοΣκάφους: "Ελληνική καρυδιά, μουριά",
  woodBack: "Greek wallnut tree, mulberry",
  μάνικο: "Φλαμούρι με κόντρες βέγγε",
  neck: "Silver linden with wenge",
  αριθμόςΔουγών : "0",
  ταστιέρα: "Έβενος",
  fretboard: "Ebony",
  κλειδιά: "Grover",
  φιγούρα: "Πλαστική",
  figure: "Plastic",
  λούστρο: "Ματ",
  vernice: "Matt",
  περιγραφή: "Επαγγελματικό στεριανό λαούτο. Στην τιμή δεν συμπεριλαμβάνεται θήκη.",
  description: "Professional stergiano laouto. Case is not included in the price.",
  τιμή: 1200,
  pictures: ["laouto/stergiano b1.jpg","laouto/stergiano b2.jpg"]
},

{
  τίτλος: "Λαούτο",
  title: "Laouto",
  id: "#Λ8Ε2",
  type: "Λαούτο",
  διαστάσειςΟργάνου: "Κλίμακα 69",
  dimensionsScale: "Scale 69",
  διαστάσειςΗχείου: {
    μήκος: "-",
    πλάτος : "-",
    βάθος: "-"
  },
  ξύλοΚαπακιού: "Έλατο Γερμανίας",
  woodSoundboard: "Spruce Germany",
  ξύλοΣκάφους: "Καρυδιά",
  woodBack: "Wallnut tree",
  μάνικο: "Φλαμούρι με 3 κόντρες βέγγε",
  neck: "Silver linden with wenge",
  αριθμόςΔουγών : "0",
  ταστιέρα: "Παλίσσανδρος",
  fretboard: "Rosewood",
  κλειδιά: "Grover",
  φιγούρα: "Ξύλινη: καρυδιά",
  figure: "Wooden (wallnut tree)",
  λούστρο: "Ματ",
  vernice: "Matt",
  περιγραφή: "Επαγγελματικό λαούτο. Στην τιμή δεν συμπεριλαμβάνεται θήκη.",
  description: "Professional stergiano laouto. Case is not included in the price.",
  τιμή: 850,
  pictures: ["laouto/laouto old.jpg"]
},

// Τζουράδες

{
  τίτλος: "Τζουράς",
  title: "Tzouras",
  id: "#TZO1",
  type: "Τζουράς",
  διαστάσειςΟργάνου: "Κλίμακα 60cm",
  dimensionsScale: "Scale 60cm",
  διαστάσειςΗχείου: {
    μήκος: "26.5cm",
    πλάτος : "13.5cm",
    βάθος: "-"
  },
  ξύλοΚαπακιού: "Κέδρος",
  woodSoundboard: "Cedar",
  ξύλοΣκάφους: "Φλαμούρι",
  woodBack: "Silver linden",
  μάνικο: "Φλαμούρι με κόντρα βέγγε",
  neck: "Silver linden with wenge",
  αριθμόςΔουγών : "0",
  ταστιέρα: "Βέγγε",
  fretboard: "Wenge",
  κλειδιά: "Dixon",
  φιγούρα: "0",
  figure: "-",
  λούστρο: "-",
  vernice: "-",
  περιγραφή: "Ημι-επαγγελματικός τζουράς. Ήχος πρίμος. Στην τιμή δεν συμπεριλαμβάνεται θήκη.",
  description: "Semi-professional tzouras. Treble sound. Case is not included in the price.",
  τιμή: 420,
  pictures: ["tzouras/tzouras skaftos 3.jpg","tzouras/tzouras skaftos 1.jpg","tzouras/tzouras skaftos 2.jpg"],
  avail:true
},

{
  τίτλος: "Τζουράς",
  title: "Tzouras",
  id: "#TZE2",
  type: "Τζουράς",
  διαστάσειςΟργάνου: "Κλίμακα 62cm",
  dimensionsScale: "Scale 62cm",
  διαστάσειςΗχείου: {
    μήκος: "-",
    πλάτος : "-",
    βάθος: "-"
  },
  ξύλοΚαπακιού: "Έλατο ΑΑΑ",
  woodSoundboard: "Spruce AAA",
  ξύλοΣκάφους: "Καρυδιά",
  woodBack: "Wallnut tree",
  μάνικο: "Φλαμούρι με κόντρα βέγγε",
  neck: "Silver linden with wenge",
  αριθμόςΔουγών : "19",
  ταστιέρα: "Παλίσανδρος",
  fretboard: "Rosewood",
  κλειδιά: "Grover",
  φιγούρα: "Καρυδιά, σφένδαμος",
  figure: "Wallnut tree, maple",
  λούστρο: "Σαντινέ",
  vernice: "Satine",
  περιγραφή: "Επαγγελματικός τζουράς. Ήχος πριμο-μεσαίος, γλυκός. Στην τιμή δεν συμπεριλαμβάνεται θήκη.",
  description: "Professional tzouras. medium-treble, sweet sound. Case is not included in the price.",
  τιμή: 600,
  pictures: ["tzouras/tzouras old.jpeg"],
},

{
  τίτλος: "Τζουράς",
  title: "Tzouras",
  id: "#TZO3",
  type: "Τζουράς",
  διαστάσειςΟργάνου: "Κλίμακα 61cm",
  dimensionsScale: "Scale 61cm",
  διαστάσειςΗχείου: {
    μήκος: "26.5cm",
    πλάτος : "13.5cm",
    βάθος: "-"
  },
  ξύλοΚαπακιού: "'Ελατο ΑΑ",
  woodSoundboard: "Spruce AA",
  ξύλοΣκάφους: "Φλαμούρι",
  woodBack: "Silver linden",
  μάνικο: "Φλαμούρι με κόντρα βέγγε",
  neck: "Silver linden with wenge",
  αριθμόςΔουγών : "0",
  ταστιέρα: "Βέγγε",
  fretboard: "Wenge",
  κλειδιά: "Dixon",
  φιγούρα: "0",
  figure: "-",
  λούστρο: "Γομολάκα",
  vernice: "Gomolaka",
  περιγραφή: "Ημι-επαγγελματικός τζουράς. Ήχος πρίμος. Στην τιμή δεν συμπεριλαμβάνεται θήκη.",
  description: "Semi-professional tzouras. Treble sound. Case is not included in the price.",
  τιμή: 420,
  pictures: ["tzouras/tzouras b1.jpg","tzouras/tzouras b2.jpg","tzouras/tzouras b3.jpg"],
  avail:true
},

//Μπαγλαμάδες

{
  τίτλος: "Μπαγλαμάς σκαφτός",
  title: "Baglamas",
  id: "#BGE0",
  type: "Μπαγλαμάς",
  διαστάσειςΟργάνου: "Κλίμακα 37cm",
  dimensionsScale: "Scale 37cm",
  διαστάσειςΗχείου: {
    μήκος: "20cm",
    πλάτος : "9.5cm",
    βάθος: "-"
  },
  ξύλοΚαπακιού: "Έλατο ΑΑΑ",
  woodSoundboard: "Spruce AAA",
  ξύλοΣκάφους: "Καρυδιά ελληνική",
  woodBack: "Greek wallnut tree",
  μάνικο: "Μαόνι με κόντρα βέγγε",
  neck: "Mahogany with wenge",
  αριθμόςΔουγών : "0",
  srips: "0",
  ταστιέρα: "'Εβενος",
  fretboard: "Ebony",
  κλειδιά: "Grover",
  φιγούρα: "-",
  figure: "-",
  λούστρο: "Γομολάκα",
  vernice: "Gomolaka",
  περιγραφή: "Επαγγελματικός μπαγλαμάς. Ήχος πρίμος. Στην τιμή δεν συμπεριλαμβάνεται θήκη.",
  description: "Professional baglamas. Treble sound. Case is not included in the price.",
  τιμή: 350,
  pictures: ["mpaglamas/mpaglamas b1.jpg","mpaglamas/mpaglamas b2.jpg","mpaglamas/mpaglamas b3.jpg"],
  avail: true
},

{
  τίτλος: "Μπαγλαμάς",
  title: "Baglamas",
  id: "#BGE1",
  type: "Μπαγλαμάς",
  διαστάσειςΟργάνου: "Κλίμακα 37cm",
  dimensionsScale: "Scale 37cm",
  διαστάσειςΗχείου: {
    μήκος: "20cm",
    πλάτος : "9.5cm",
    βάθος: "-"
  },
  ξύλοΚαπακιού: "Έλατο ΑΑΑ",
  woodSoundboard: "Spruce AAA",
  ξύλοΣκάφους: "Καρυδιά ελληνική",
  woodBack: "Greek wallnut tree",
  μάνικο: "Μαόνι με κόντρα βέγγε",
  neck: "Mahogany with wenge",
  αριθμόςΔουγών : "0",
  srips: "0",
  ταστιέρα: "'Εβενος",
  fretboard: "Ebony",
  κλειδιά: "Grover",
  φιγούρα: "-",
  figure: "-",
  λούστρο: "Γομολάκα",
  vernice: "Gomolaka",
  περιγραφή: "Επαγγελματικός μπαγλαμάς. Ήχος πρίμος. Στην τιμή δεν συμπεριλαμβάνεται θήκη.",
  description: "Professional baglamas. Treble sound. Case is not included in the price.",
  τιμή: 350,
  pictures: ["mpaglamas/mpaglamas 1.jpg","mpaglamas/mpaglamas 2.jpg","mpaglamas/mpaglamas 3.jpg"]
},

{
  τίτλος: "Μπαγλαμάς με δούγες",
  title: "Baglamas with srips",
  id: "#BGE2",
  type: "Μπαγλαμάς",
  διαστάσειςΟργάνου: "Κλίμακα 38cm",
  dimensionsScale: "Scale 38cm",
  διαστάσειςΗχείου: {
    μήκος: "-",
    πλάτος : "-",
    βάθος: "-"
  },
  ξύλοΚαπακιού: "Έλατο ΑΑΑ",
  woodSoundboard: "Spruce AAA",
  ξύλοΣκάφους: "Καρυδιά φυσική",
  woodBack: "Natural wallnut tree",
  μάνικο: "Φλαμούρι με κόντρα βέγγε",
  neck: "Silver linden with wenge",
  αριθμόςΔουγών : "15",
  srips: "15",
  ταστιέρα: "'Εβενος",
  fretboard: "Ebony",
  κλειδιά: "Grover",
  φιγούρα: "Ξύλινη: παλίσανδρος,σφένδαμος,μαόνι",
  figure: "Wooden: rosewood,maple,mahogany",
  λούστρο: "Ματ",
  vernice: "Matt",
  περιγραφή: "Επαγγελματικός μπαγλαμάς. Ήχος πρίμος γλυκός. Στην τιμή δεν συμπεριλαμβάνεται θήκη.",
  description: "Professional baglamas. Treble sweet sound. Case is not included in the price.",
  τιμή: 400,
  pictures: ["mpaglamas/mpaglamas old 1.jpeg"]
},

{
  τίτλος: "Μπαγλαμάς με δούγες μακρόστενος",
  title: "Oblong baglamas with srips",
  id: "#BGE3",
  type: "Μπαγλαμάς",
  διαστάσειςΟργάνου: "Κλίμακα 38cm",
  dimensionsScale: "Scale 38cm",
  διαστάσειςΗχείου: {
    μήκος: "-",
    πλάτος : "-",
    βάθος: "-"
  },
  ξύλοΚαπακιού: "Έλατο ΑΑΑ",
  woodSoundboard: "Spruce AAA",
  ξύλοΣκάφους: "Καρυδιά",
  woodBack: "Wallnut tree",
  μάνικο: "Φλαμούρι με κόντρα βέγγε",
  neck: "Silver linden with wenge",
  αριθμόςΔουγών : "-",
  srips: "-",
  ταστιέρα: "'Εβενος",
  fretboard: "Ebony",
  κλειδιά: "Grover",
  φιγούρα: "Ξύλινη: καρυδιά,σφένδαμος",
  figure: "Wooden: wallnut tree,maple",
  λούστρο: "Ματ",
  vernice: "Matt",
  περιγραφή: "Επαγγελματικός μπαγλαμάς. Ήχος πρίμος γλυκός. Στην τιμή δεν συμπεριλαμβάνεται θήκη.",
  description: "Professional baglamas. Treble sweet sound. Case is not included in the price.",
  τιμή: 400,
  pictures: ["mpaglamas/mpaglamas old 2.jpeg"]
},

{
  τίτλος: "Μπαγλαμάς γκρινιάρης σκαφτός",
  title: "Baglamas",
  id: "#BGE4",
  type: "Μπαγλαμάς",
  διαστάσειςΟργάνου: "Κλίμακα 36cm",
  dimensionsScale: "Scale 36cm",
  διαστάσειςΗχείου: {
    μήκος: "-",
    πλάτος : "-",
    βάθος: "-"
  },
  ξύλοΚαπακιού: "Έλατο ΑΑΑ",
  woodSoundboard: "Spruce AAA",
  ξύλοΣκάφους: "Καρυδιά ελληνική",
  woodBack: "Greek wallnut tree",
  μάνικο: "Φλαμούρι με κόντρα βέγγε",
  neck: "Silver linden with wenge",
  αριθμόςΔουγών : "0",
  srips: "0",
  ταστιέρα: "'Εβενος",
  fretboard: "Ebony",
  κλειδιά: "Grover",
  φιγούρα: "Ξύλινη: καρυδιά,σφένδαμος",
  figure: "Wooden: wallnut tree,maple",
  λούστρο: "Γυαλιστερό",
  vernice: "Shiny",
  περιγραφή: "Επαγγελματικός μπαγλαμάς με πράσινα όστρακα στους οδηγούς. Ήχος πρίμος. Στην τιμή δεν συμπεριλαμβάνεται θήκη.",
  description: "Professional baglamas with green seashells on the fretboard. Treble sound. Case is not included in the price.",
  τιμή: 370,
  pictures: ["mpaglamas/mpaglamas old 3.jpg"]
},


//Μαντολίνα

{
  τίτλος: "Μαντολίνο",
  title: "Mantolino",
  id: "#MPE1",
  type: "Μαντολίνο",
  διαστάσειςΟργάνου: "-",
  dimensionsScale: "-",
  διαστάσειςΗχείου: {
    μήκος: "-",
    πλάτος : "",
    βάθος: "-"
  },
  ξύλοΚαπακιού: "Έλατο ΑΑ",
  woodSoundboard: "Spruce AA",
  ξύλοΣκάφους: "Καρυδιά ελληνική",
  woodBack: "Greek wallnut tree",
  μάνικο: "Καρυδιά ελληνική",
  neck: "Greek wallnut tree",
  αριθμόςΔουγών : "0",
  srips: "0",
  ταστιέρα: "Παλίσανδρος",
  fretboard: "Maple",
  κλειδιά: "Grover",
  φιγούρα: "-",
  figure: "-",
  λούστρο: "-",
  vernice: "-",
  περιγραφή: "-",
  description: "-",
  τιμή: 750,
  pictures: ["mantolino/antoni (1).jpg","mantolino/antoni (3).jpg"]
},

/*
* Νει
*/

{
  title: "Νέι",
  id: "#NBE1",
  type: "Νέι",
  διαστάσειςΟργάνου: "-",
  διαστάσειςΗχείου: {
    μήκος: "-",
    πλάτος : "-",
    βάθος: "-"
  },
  ξύλοΚαπακιού: "-",
  ξύλοΣκάφους: "-",
  μάνικο: "-",
  αριθμόςΔουγών : "0",
  ταστιέρα: "-",
  κλειδιά: "-",
  φιγούρα: "-",
  λούστρο: "-",
  description: "",
  τιμή: 100,
  pictures: ["nei/nei.jpeg"]
},

/*
* Διάφορα
*/

{
  τίτλος: "Λαουτοκίθαρο",
  title: "Laouto-guitar",
  id: "#LGE1",
  type: "Πατέντες",
  διαστάσειςΟργάνου: "Κλίμακα 69.5",
  dimensionsScale: "Scale 69.5",
  διαστάσειςΗχείου: {
    μήκος: "-",
    πλάτος : "",
    βάθος: "-"
  },
  ξύλοΚαπακιού: "Έλατο ΑΑΑ",
  woodSoundboard: "Spruce AAA",
  ξύλοΣκάφους: "Καρυδιά",
  woodBack: "Wallnut tree",
  μάνικο: "Φλαμούρι με κόντρα βέγγε",
  neck: "Silver linden with wenge",
  αριθμόςΔουγών : "0",
  srips: "0",
  ταστιέρα: "Παλίσανδρος",
  fretboard: "Rosewood",
  κλειδιά: "Schaller",
  φιγούρα: "Σφένδαμος, μπουμπίγκα",
  figure: "Maple",
  λούστρο: "Σαντινέ",
  vernice: "Satine",
  περιγραφή: "Επαγγελματικό λαουτοκίθαρο. Ήχος μπασο-μεσαίος. Στην τιμή δεν συμπεριλαμβάνεται θήκη.",
  description: "Professional laouto-guitar. Medium-bass sound. Case is not included in the price.",
  τιμή: 1100,
  pictures: ["other/laoutokitharo 1.jpg","other/laoutokitharo 2.jpg"]
},

{
  τίτλος: "Μπουζούκι οκτάχορδο πατέντα",
  title: "8-chourse bouzouki patent",
  id: "#BPE2",
  type: "Πατέντες",
  διαστάσειςΟργάνου: "Κλίμακα 68",
  dimensionsScale: "Scale 68",
  διαστάσειςΗχείου: {
    μήκος: "-",
    πλάτος : "",
    βάθος: "-"
  },
  ξύλοΚαπακιού: "Έλατο ΑΑΑ",
  woodSoundboard: "Spruce AAA",
  ξύλοΣκάφους: "Σφένδαμος",
  woodBack: "Maple",
  μάνικο: "Φλαμούρι με κόντρα βέγγε",
  neck: "Silver linden with wenge",
  αριθμόςΔουγών : "30",
  srips: "30",
  ταστιέρα: "Έβενος",
  fretboard: "Ebony",
  κλειδιά: "Mechanical",
  φιγούρα: "-",
  figure: "-",
  λούστρο: "Σαντινέ",
  vernice: "Satine",
  περιγραφή: "Επαγγελματικό οκτάχορδο μπουζούκι (πατέντα) με σκαλιστή ροζέτα από σφένδαμο. Στην τιμή δεν συμπεριλαμβάνεται θήκη.",
  description: "Professional 8-course. Case is not included in the price.",
  τιμή: 1500,
  pictures: ["other/kyrkou 1.jpg","other/kyrkou 2.jpg","other/kyrkou 3.jpg","other/kyrkou 4.jpg"]
},
]